import { graphql } from "gatsby"
import { CompanyService } from "../CompanyService/CompanyService"

export const query = graphql`
  query ($fileAbsolutePath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $fileAbsolutePath }) {
      frontmatter {
        title
        summary
        buttonText
        landingImage {
          childImageSharp {
            gatsbyImageData
          }
        }
        businessUseCases {
          title
          description
          buttonText
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        statistics {
          title
          statistic {
            icon {
              childImageSharp {
                gatsbyImageData
              }
            }
            value
          }
        }
        finalPitch {
          title
          description
          buttonText
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    site {
      ...SiteFragment
    }
    happyClients: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/happyClients.md/" }
    ) {
      frontmatter {
        title
        subtitle
        happyClients
      }
    }
    serviceDetail: markdownRemark(
      fileAbsolutePath: { regex: "/netlify/serviceDetail.md/" }
    ) {
      frontmatter {
        insightsSection {
          caption
          title
          featuredInsights
        }
        newsletter {
          sideImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    insights: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/blog/" } }
    ) {
      nodes {
        fileAbsolutePath
        frontmatter {
          title
          category
          summary
          featuredImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allCaseStudies: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/netlify/case_studies/" } }
    ) {
      nodes {
        frontmatter {
          client
          logo {
            childImageSharp {
              gatsbyImageData(height: 40)
            }
          }
        }
      }
    }
    header: markdownRemark(fileAbsolutePath: { regex: "/netlify/header.md/" }) {
      ...HeaderFragment
    }
    footer: markdownRemark(fileAbsolutePath: { regex: "/netlify/footer.md/" }) {
      ...FooterFragment
    }
  }
`

export default CompanyService
