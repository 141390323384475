import { PageProps } from "gatsby"
import styled from "@emotion/styled"
import React from "react"
import ReactMarkdown from "react-markdown"
import { Typography } from "../components/Typography/Typography"
import { Layout } from "../components/Layout/Layout"
import { BasePageData } from "../components/Layout/layout.hocs"
import {
  getFooterProps,
  getHeaderProps,
} from "../components/Layout/layout.utils"
import {
  GatsbyImage,
  getImage,
  getSrc,
  IGatsbyImageData,
  ImageDataLike,
} from "gatsby-plugin-image"
import { breakpoint } from "../theme/theme"
import { PlainLink } from "../components/PlainLink/PlainLink"
import { PrimaryButton } from "../components/PrimaryButton/PrimaryButton"
import { Paper } from "../components/Paper/Paper"
import {
  ClientCaseStudyGraphQLData,
  getHappyClients,
  HappyClientsGraphQLData,
} from "../components/HappyClients/happyClients.utils"
import { getInsights } from "../Home/home.utils"
import { getFilteredFeatures, getSlugFromTitle } from "../utils/slug"
import { BlogSummaryCard } from "../components/BlogSummary/BlogSummaryCard"
import { Button } from "../components/Button/Button"
import { AccentDivider } from "../components/AccentDivider/AccentDivider"
import { NewsletterCard } from "../components/NewsletterCard/NewsletterCard"
import { HappyClients } from "../components/HappyClients/HappyClients"

interface CompanyServiceData extends BasePageData {
  markdownRemark: {
    frontmatter: {
      title: string
      summary: string
      buttonText: string
      landingImage: ImageDataLike
      businessUseCases: {
        title: string
        description: string
        image: ImageDataLike
        buttonText: string
      }
      statistics: {
        title: string
        statistic: Array<{
          icon: ImageDataLike
          value: string
        }>
      }
      finalPitch: {
        title: string
        description: string
        buttonText: string
        image: ImageDataLike
      }
    }
  }
  happyClients: HappyClientsGraphQLData
  allCaseStudies: ClientCaseStudyGraphQLData
  serviceDetail: {
    frontmatter: {
      insightsSection: {
        caption: string
        title: string
        featuredInsights: Array<string>
      }
      newsletter: {
        sideImage: ImageDataLike
      }
    }
  }
  insights: {
    nodes: Array<{
      fileAbsolutePath: string
      frontmatter: {
        title: string
        category: string
        summary: string
        featuredImage: ImageDataLike
      }
    }>
  }
}

type CompanyServiceProps = PageProps<CompanyServiceData>

const getProcessedImage = (coverImage: ImageDataLike) => {
  const image = process.env.STORYBOOK
    ? (coverImage as IGatsbyImageData)
    : getImage(coverImage)
  if (!image) {
    throw new Error("Could not find cover image")
  }

  return image
}

const getProcessedStatistics = (
  statistic: Array<{ icon: ImageDataLike; value: string }>
) => {
  return statistic.map((stat) => {
    const image = getProcessedImage(stat.icon)
    return {
      ...stat,
      icon: image,
    }
  })
}

export const CompanyService: React.FC<CompanyServiceProps> = (props) => {
  const {
    title,
    summary,
    buttonText,
    landingImage,
    businessUseCases,
    statistics,
    finalPitch,
  } = props.data.markdownRemark.frontmatter
  const { happyClients, insights, serviceDetail, allCaseStudies } = props.data
  const { insightsSection, newsletter } = serviceDetail.frontmatter
  const siteData = props.data.site.siteMetadata
  const headerProps = props.data.header
  const footerProps = props.data.footer
  const processedLandingImage = getProcessedImage(landingImage)
  const processedUsecaseImage = getProcessedImage(businessUseCases.image)
  const processedStatistics = getProcessedStatistics(statistics.statistic)
  const processedFinalpitchImage = getProcessedImage(finalPitch.image)
  const clients = getHappyClients(
    happyClients.frontmatter.happyClients,
    allCaseStudies
  )

  const processedInsights = getInsights(
    getFilteredFeatures(insightsSection.featuredInsights, insights.nodes)
  )
  const newsletterImage = getProcessedImage(newsletter.sideImage)
  const socialPreviewImage = getSrc(processedLandingImage)

  return (
    <Layout
      title={`${title} | ${siteData.title}`}
      description={finalPitch.description}
      imageURL={`${siteData.siteUrl}${socialPreviewImage}`}
      header={getHeaderProps(headerProps, "light")}
      footer={getFooterProps(footerProps)}
    >
      <div>
        <ContentContainer>
          <SummaryContainer reverseOnMobile>
            <SummaryContent>
              <SummaryTitle variant="h2">{title}</SummaryTitle>
              <ReactMarkdown
                components={{
                  p: ({ node: _node, ...props }) => (
                    <SummaryText variant="p" {...props} />
                  ),
                }}
              >
                {summary}
              </ReactMarkdown>
              <div>
                <PlainLink to={`/contact`}>
                  <PrimaryButton>{buttonText}</PrimaryButton>
                </PlainLink>
              </div>
            </SummaryContent>
            <ImageContainer>
              <GatsbyImage image={processedLandingImage} alt={title} />
            </ImageContainer>
          </SummaryContainer>
          <SummaryContainer>
            <ImageContainer>
              <GatsbyImage
                image={processedUsecaseImage}
                alt={businessUseCases.title}
              />
            </ImageContainer>
            <SummaryContent>
              <Typography variant="h4">{businessUseCases.title}</Typography>
              <ReactMarkdown
                components={{
                  p: ({ node: _node, ...props }) => (
                    <SummaryText variant="p" {...props} />
                  ),
                }}
              >
                {businessUseCases.description}
              </ReactMarkdown>
              <div>
                <PlainLink to={`/contact`}>
                  <PrimaryButton>{businessUseCases.buttonText}</PrimaryButton>
                </PlainLink>
              </div>
            </SummaryContent>
          </SummaryContainer>
        </ContentContainer>
        <Paper color="darkblue">
          <StatisticsSection>
            <StatisticsTitle variant="h3">{statistics.title}</StatisticsTitle>
            <StatisticsContainer>
              {processedStatistics.map((stat, index) => (
                <Statistic key={`${index}-${stat.value}`}>
                  <StatisticIcon>
                    <GatsbyImage image={stat.icon} alt={stat.value} />
                  </StatisticIcon>
                  <SummaryText variant="p">{stat.value}</SummaryText>
                </Statistic>
              ))}
            </StatisticsContainer>
          </StatisticsSection>
        </Paper>
        <FinalPitch>
          <SummaryContainer reverseOnMobile>
            <SummaryContent>
              <Typography variant="h4">{finalPitch.title}</Typography>
              <ReactMarkdown
                components={{
                  p: ({ node: _node, ...props }) => (
                    <SummaryText variant="p" {...props} />
                  ),
                }}
              >
                {finalPitch.description}
              </ReactMarkdown>
              <div>
                <PlainLink to={`/contact`}>
                  <PrimaryButton>{finalPitch.buttonText}</PrimaryButton>
                </PlainLink>
              </div>
            </SummaryContent>
            <ImageContainer>
              <GatsbyImage
                image={processedFinalpitchImage}
                alt={finalPitch.title}
              />
            </ImageContainer>
          </SummaryContainer>
        </FinalPitch>
        <HappyClientsSection>
          <HappyClients
            title={happyClients.frontmatter.title}
            subtitle={happyClients.frontmatter.subtitle}
            clients={clients}
          />
        </HappyClientsSection>
        <InsightsSection color="darkblue">
          <InsightsTitleSection>
            <InsightsTitle>
              <InsightsCaption>
                <CaptionText variant="h6">
                  {insightsSection.caption}
                </CaptionText>
                <AccentDivider />
              </InsightsCaption>
              <Typography variant="h2">{insightsSection.title}</Typography>
            </InsightsTitle>
            <PlainLink to="/insights">
              <Button variant="outlined">View All</Button>
            </PlainLink>
          </InsightsTitleSection>
          <InsightsContainer>
            {processedInsights.map(
              ({ title, category, summary, featuredImage }) => {
                const link = `/insights/${getSlugFromTitle(title)}`

                return (
                  <BlogSummaryCard
                    key={title}
                    title={title}
                    category={category}
                    description={summary}
                    coverImage={featuredImage}
                    link={link}
                    darkBlueBg={true}
                  />
                )
              }
            )}
          </InsightsContainer>
        </InsightsSection>
        <NewsletterSection>
          <NewsletterCard sideImage={newsletterImage} />
        </NewsletterSection>
      </div>
    </Layout>
  )
}

const MaxWidthContainer = styled("div")({
  margin: "0 auto",
  width: "auto",
  padding: "0 2em",
  [breakpoint("lg")]: {
    maxWidth: "1200px",
  },
})

const SummaryContainer = styled("div")<{ reverseOnMobile?: boolean }>(
  ({ reverseOnMobile }) => ({
    display: "flex",
    flexDirection: reverseOnMobile ? "column-reverse" : "column",
    gap: "2em",
    [breakpoint("md")]: {
      flexDirection: "row",
    },
  })
)

const SummaryContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
  color: theme.palette.darkblue.main,
  flex: 1,
}))

const ImageContainer = styled("div")({
  flex: 1,
})

const SummaryTitle = styled(Typography)({
  fontSize: "32px",
  [breakpoint("sm")]: {
    fontSize: "40px",
  },
})

const SummaryText = styled(Typography)({
  lineHeight: "24px",
})

const ContentContainer = styled(MaxWidthContainer)({
  display: "flex",
  flexDirection: "column",
  padding: "2em",
  gap: "4em",
  [breakpoint("sm")]: {
    padding: "6em 2em",
  },
})

const StatisticsSection = styled(MaxWidthContainer)({
  display: "flex",
  flexDirection: "column",
  gap: "4em",
  padding: "4em 2em",
})

const StatisticsTitle = styled(Typography)({
  [breakpoint("sm")]: {
    textAlign: "center",
  },
})

const StatisticsContainer = styled("div")({
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "2em",

  [breakpoint("md")]: {
    gridTemplateColumns: "1fr 1fr 1fr",
  },
})

const Statistic = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const StatisticIcon = styled("div")(({ theme }) => ({
  height: "80px",
  width: "78px",
  borderRadius: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.secondary.main,
}))

const FinalPitch = styled(MaxWidthContainer)({
  padding: "4em 2em",
})

const HappyClientsSection = styled(MaxWidthContainer)({
  padding: "4em 2em",
})
const InsightsSection = styled(Paper)({
  padding: "10em 2em",
  flexDirection: "column",
  gap: "4em",
})

const InsightsTitleSection = styled(MaxWidthContainer)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  flexDirection: "column",
  gap: "1em",
  padding: "0",
  [breakpoint("lg")]: {
    padding: "0 2em",
  },
  [breakpoint("sm")]: {
    flexDirection: "row",
    gap: "0",
  },
})

const InsightsTitle = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "2em",
})

const InsightsContainer = styled(MaxWidthContainer)({
  display: "grid",
  padding: "0",
  gridTemplateColumns: "1fr",
  gap: "2em",
  [breakpoint("md")]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [breakpoint("lg")]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
})

const InsightsCaption = styled("div")({
  display: "flex",
  gap: "1em",
  alignItems: "center",
})

const CaptionText = styled(Typography)({
  textTransform: "uppercase",
  letterSpacing: "0",
  [breakpoint("sm")]: {
    letterSpacing: "0.32em",
  },
})

const NewsletterSection = styled(MaxWidthContainer)({
  marginTop: "-120px",
  display: "flex",
  justifyContent: "center",
})
